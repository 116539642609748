"use client";

import React, { useEffect } from "react";
import Smartlook from "smartlook-client";

function SmartlookClient() {
  useEffect(() => {
    if (!Smartlook.initialized()) {
      Smartlook.init("830fd8294ff671b9e2921ccf4c0aa2781ca4e260", {
        region: "eu",
        advancedNetwork: {
          allowedUrls: [
            new RegExp("^https://travelthru.com/"),
            new RegExp("^https://account.chauffeurbookingsoftware.com/"),
          ],
          allowedHeaders: ["x-custom-header"],
          websockets: true,
        },
      });

      Smartlook.record({
        forms: true,
        numbers: false,
        emails: true,
        ips: true,
      });
    }
  }, []);
  return <></>;
}

export default SmartlookClient;
